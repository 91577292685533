import React, { useContext } from 'react'
import '../css/components/Contacts.css'
import { useParams } from 'react-router-dom'
import Context from '../Context'
import Container from './Container'

const Contacts = () => {
  const { id } = useParams()

  const { sitesData } = useContext(Context)
  const site = sitesData[id]

  return (
    <div className="contacts">
      <Container name={'Names:'}>
        {site.contactName.map((site, i) => site !== ' ' && <div key={i}>{site}</div>)}
      </Container>
      <Container name={'Phone Numbers:'}>
        {site.contactNumbers.map((site, i) => site !== ' ' && <div key={i}>{site}</div>)}
      </Container>
    </div>
  )
}

export default Contacts
