import React, { useContext } from 'react'
import '../css/components/Notes.css'
import { useParams } from 'react-router-dom'
import Context from '../Context'
import Container from './Container'

const Notes = () => {
  const { id } = useParams()
  const { sitesData } = useContext(Context)
  const site = sitesData[id]

  return (
    <div className="notes">
      <Container>{site.notes}</Container>
    </div>
  )
}

export default Notes
