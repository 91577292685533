import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import Context from '../Context'
import '../css/pages/CallLog.css'
import CallLogComponent from '../components/CallLogComponent'
import { useNavigate } from 'react-router-dom'
import Loading from '../components/Loading'

const CallLog = () => {
  const { callLogData, status } = useContext(Context)
  const [loading, setLoading] = useState()
  let navigate = useNavigate()

  useEffect(() => {
    if (callLogData && callLogData.length === 0) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [callLogData])

  useEffect(() => {
    if (status && status === 401) {
      navigate('/')
    }
  }, [status])

  return (
    <div className="calllog-page" style={{ padding: '25px' }}>
      {loading ? <CallLogComponent data={callLogData} /> : <Loading />}
    </div>
  )
}

export default CallLog
