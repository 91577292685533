import React from 'react'
import '../css/components/Container.css'

const Container = ({ children, name }) => {
  return (
    <div className="Container">
      <h3 className="Container-header">{name}</h3>
      <div className="Container-content">{children}</div>
    </div>
  )
}

export default Container
