import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import axios from 'axios'

import Address from '../components/Address'
import Contacts from '../components/Contacts'
import Notes from '../components/Notes'
import Blis from '../components/Blis'
import Traditional from '../components/Traditional'
import CallLogComponent from '../components/CallLogComponent'
import Context from '../Context'

import '../css/pages/SiteInfo.css'
import Loading from '../components/Loading'

const SiteInfo = () => {
  const [toggleState, setToggleState] = useState(1)
  const [cookies] = useCookies()
  const { id } = useParams()
  const { callLogData, sitesData, status } = useContext(Context)
  const site = sitesData[id]
  const baseUrl = 'https://bingoplus24.com:55770'
  const [loading, setLoading] = useState()

  let navigate = useNavigate()

  useEffect(() => {
    if (sitesData.length === 0) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [sitesData])

  useEffect(() => {
    if (status && status === 401) {
      navigate('/')
    }
  }, [status])

  const toggleTab = (index) => {
    setToggleState(index)
  }
  return (
    <div className="siteinfo">
      {loading ? (
        <>
          <div className="tabs-bar">
            <div className={toggleState === 1 ? 'tabs-active' : 'tabs'} onClick={() => toggleTab(1)}>
              Address
            </div>
            <div className={toggleState === 2 ? 'tabs-active' : 'tabs'} onClick={() => toggleTab(2)}>
              Contacts
            </div>
            <div className={toggleState === 3 ? 'tabs-active' : 'tabs'} onClick={() => toggleTab(3)}>
              Notes
            </div>
            {site.blis && (
              <div className={toggleState === 4 ? 'tabs-active' : 'tabs'} onClick={() => toggleTab(4)}>
                BLIS
              </div>
            )}
            {site.traditional && (
              <div className={toggleState === 5 ? 'tabs-active' : 'tabs'} onClick={() => toggleTab(5)}>
                Traditional
              </div>
            )}
            <div className={toggleState === 6 ? 'tabs-active' : 'tabs'} onClick={() => toggleTab(6)}>
              Call log
            </div>
          </div>
          <div className="tabs-content">
            <div className={toggleState === 1 ? 'tabs-active' : 'tabs-inactive'}>
              <Address />
            </div>
          </div>
          <div className="tabs-content">
            <div className={toggleState === 2 ? 'tabs-active' : 'tabs-inactive'}>
              <Contacts />
            </div>
          </div>
          <div className="tabs-content">
            <div className={toggleState === 3 ? 'tabs-active' : 'tabs-inactive'}>
              <Notes />
            </div>
          </div>
          <div className="tabs-content">
            <div className={toggleState === 4 ? 'tabs-active' : 'tabs-inactive'}>
              <Blis />
            </div>
          </div>
          <div className="tabs-content">
            <div className={toggleState === 5 ? 'tabs-active' : 'tabs-inactive'}>
              <Traditional />
            </div>
          </div>
          <div className="tabs-content">
            <div className={toggleState === 6 ? 'tabs-active' : 'tabs-inactive'}>
              {callLogData && (
                <CallLogComponent
                  data={callLogData.filter((s) => s.siteName === site.siteName && s.groupName === site.groupName)}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default SiteInfo
