import React, { useEffect } from 'react'
import { useContext, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import Container from '../components/Container'
import Context from '../Context'
import { useCookies } from 'react-cookie'
import Loading from '../components/Loading'

import '../css/pages/ClubInfo.css'

const ClubInfo = () => {
  const { sitesData, status } = useContext(Context)
  let sites = sitesData
  const [filter, setFilter] = useState('')
  const [loading, setLoading] = useState()
  const [cookies] = useCookies()
  let navigate = useNavigate()

  useEffect(() => {
    if (sitesData && sitesData.length === 0) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [sitesData])

  useEffect(() => {
    if (!cookies.jwt) {
      navigate('/')
    } else if (!cookies.jwt && status && status.code === 401) {
      navigate('/')
    }
  }, [cookies, status])

  return (
    <div className="clubinfo">
      {loading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: `${window.innerWidth > 900 ? 'row' : 'column'}`,
            justifyContent: 'space-between'
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <input
              className="filter-field"
              type="text"
              placeholder="Search"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <div className="container">
              <div className="container-content">
                <div className="clubinfo-table">
                  <div className="clubinfo-row">
                    <div className="clubinfo-column" style={{ fontWeight: '700', fontSize: '19px' }}>
                      Site
                    </div>
                    <div className="clubinfo-column" style={{ fontWeight: '700', fontSize: '19px' }}>
                      Group
                    </div>
                  </div>

                  {sites
                    .sort((a, b) =>
                      a.siteName.toLowerCase() + ' ' + a.groupName.toLowerCase() >
                      b.siteName.toLowerCase() + ' ' + b.groupName.toLowerCase()
                        ? 1
                        : -1
                    )
                    .map(
                      (site, i) =>
                        (site.siteName.toLowerCase() + ' ' + site.groupName.toLowerCase()).includes(
                          filter.toLowerCase()
                        ) && (
                          <div key={site.id} className="clubinfo-table-rows">
                            <Link to={`/clubinfo/${i}`}>
                              <div className="clubinfo-row">
                                <div className="clubinfo-column">{site.siteName}</div>
                                <div className="clubinfo-column"> {site.groupName}</div>
                              </div>
                            </Link>
                          </div>
                        )
                    )}
                </div>
              </div>
            </div>
          </div>
          {window.innerWidth > 900 && (
            <div style={{ width: '70%', height: '90vh' }}>
              <Outlet />
            </div>
          )}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default ClubInfo
