import axios from 'axios'

const baseUrl = 'https://bingoplus24.com:55770'

export const getSites = async (cookies, state, status) => {
  await axios
    .get(`${baseUrl}/getSites`, {
      headers: {
        Authorization: `token ${cookies.jwt}`
      }
    })
    .then((response) => {
      state(response.data)
      status(response.status)
    })
    .catch(function (error) {
      console.log('error', error)
      if (error.response.status) status(error.response.status)
    })
}

export const getCallLog = async (cookies, state) => {
  await axios
    .get(`${baseUrl}/getCallLog`, {
      headers: {
        Authorization: `token ${cookies.jwt}`
      }
    })
    .then((response) => {
      state(response.data)
    })
    .catch(function (error) {
      console.log('error', error)
    })
}
