import React, { useState } from 'react'
import '../css/pages/Login.css'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Loading from '../components/Loading'

const Login = () => {
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [cookies, setCookie] = useCookies(['user'])
  const [style, setStyle] = useState({ visibility: 'hidden' })
  const [loading, setLoading] = useState()
  const [errorMessage, setErrorMessage] = useState()
  let navigate = useNavigate()

  const login = () => {
    setLoading(true)
    axios
      .get('https://bingoplus24.com:55770/login', {
        auth: {
          username: name,
          password: password
        }
      })
      .then((res) => {
        //save the jwt in a cookie
        setCookie('jwt', res.data, { path: '/', expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365) })
        setStyle({ visibility: 'hidden' })

        navigate('/clubInfo')

        setLoading(false)
      })
      .catch(function (error) {
        setStyle({ visibility: 'visible' })
        setErrorMessage(error.message)
        setLoading(false)
      })
  }

  const handle = () => {
    login()
  }

  return (
    <div className="login">
      <div className="appName">LEDB App</div>
      <div className="form">
        <div className="login-section">
          <label>username:</label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)}></input>
        </div>
        <div className="login-section">
          <label>password:</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)}></input>
        </div>
        <button onClick={handle}>login</button>
        <div className="invalid-cred" style={style}>
          {errorMessage}
        </div>
        {loading ? <Loading /> : <div style={{ width: '35px', height: '35px', marginTop: '10px' }}></div>}
      </div>
    </div>
  )
}

export default Login
