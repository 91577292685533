import React from 'react'
import { Outlet, Link, useLocation } from 'react-router-dom'

import '../css/Layout.css'

const Layout = () => {
  let location = useLocation().pathname
  return (
    <>
      {location !== '/' && (
        <nav className="navbar">
          <>
            <div className="navbar-tab">
              <Link to="/clubinfo">ClubInfo</Link>
            </div>
            <div className="navbar-tab">
              <Link to="/calllog">CallLog</Link>
            </div>
            <div className="navbar-tab">
              <Link to="/calendar">Calendar</Link>
            </div>
          </>
        </nav>
      )}

      <Outlet />
    </>
  )
}

export default Layout
