import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Context from './Context'
import './css/Global.css'
import { useCookies } from 'react-cookie'
import { getSites, getCallLog } from './apiCalls.js'

//import pages
import Calendar from './pages/Calendar'
import ClubInfo from './pages/ClubInfo'
import CallLog from './pages/CallLog'
import Layout from './pages/Layout'
import SiteInfo from './pages/SiteInfo'
import Login from './pages/Login'

function App() {
  const [sitesData, setSitesData] = useState([])
  const [callLogData, setCallLogData] = useState([])
  const [status, setStatus] = useState('')
  const [cookies] = useCookies()

  useEffect(() => {
    //only do the next requests after the previous ones have been successfull
    getSites(cookies, setSitesData, setStatus).then(() => getCallLog(cookies, setCallLogData))
  }, [cookies])

  return (
    <Context.Provider value={{ status, sitesData, callLogData }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Login />} />
            {window.innerWidth > 900 ? (
              <Route path="clubinfo" element={<ClubInfo />}>
                <Route path=":id" element={<SiteInfo />} />
              </Route>
            ) : (
              <>
                <Route path="clubinfo" element={<ClubInfo />} />
                <Route path="clubinfo/:id" element={<SiteInfo />} />
              </>
            )}

            <Route path="calllog" element={<CallLog />} />
            <Route path="calendar" element={<Calendar />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Context.Provider>
  )
}

export default App
