import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import Context from '../Context'
import '../css/components/Traditional.css'
import Container from './Container'
import DataField from './DataField'

const Traditional = () => {
  const { id } = useParams()
  const { sitesData } = useContext(Context)
  const traditional = sitesData[id].traditional

  return (
    <div className="traditional">
      {traditional ? (
        <Container>
          <div className="label">SRCE Version:</div>
          <DataField>{traditional.tradSRCEVersion}</DataField>
          <div className="label">SRCE Options:</div>
          <DataField>{traditional.tradSRCEOptions}</DataField>
          <div className="label">SRCE Boards:</div>
          <DataField>{traditional.tradSRCEBoards}</DataField>
          <div className="label">RNGE Version:</div>
          <DataField>{traditional.tradRNGEVersion}</DataField>
          <div className="label">RNGE Options:</div>
          <DataField>{traditional.tradRNGEOptions}</DataField>
          <div className="label">RNGE Boards:</div>
          <DataField>{traditional.tradRNGEBoards}</DataField>
          <div className="label">Player Boards:</div>
          <DataField>{traditional.tradPlayerBoards}</DataField>
          <div className="label">ED Displays:</div>
          <DataField>{traditional.tradEDDisplays}</DataField>
          <div className="label">Mechs:</div>
          <DataField>{traditional.tradMechs}</DataField>
          <div className="label">Claim Buttons:</div>
          <DataField>{traditional.tradClaimButtons}</DataField>
          <div className="label">Bonus Buttons:</div>
          <DataField>{traditional.tradBonusButtons}</DataField>
          <div className="label">Cash Box Lock Number:</div>
          <DataField>{traditional.tradCashBoxLockNumber}</DataField>
          <div className="label">Outdoor Lock Number:</div>
          <DataField>{traditional.tradOutdoorLockNumber}</DataField>
        </Container>
      ) : null}
    </div>
  )
}

export default Traditional
