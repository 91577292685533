import React, { useState } from 'react'
import '../css/components/CallLogEntry.css'
import Container from './Container'

const CallLogEntry = ({ id, date, status, product, engineer, group, site, problem, action }) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <div>
      <div className="call-log-entry-title" onClick={() => setIsActive(!isActive)}>
        <div className="calllog-row">
          <div className="calllog-column"> {status}</div>
          <div className="calllog-column"> {date.split('T')[0]}</div>
          <div className="calllog-column"> {group}</div>
          <div className="calllog-column"> {site}</div>
          <div />
        </div>
        {isActive && (
          <div className="call-log-content">
            <div className="data">
              {'ID: ' + id} <br />
              {'Engineer: ' + engineer} <br />
              {'Product: ' + product}
            </div>
            <Container name={'Detail'}>{problem}</Container>
            <br />
            <Container name={'Action'}>{action}</Container>
          </div>
        )}
      </div>
    </div>
  )
}

export default CallLogEntry
