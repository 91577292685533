import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import Context from '../Context'
import '../css/components/Blis.css'
import Container from './Container'
import DataField from './DataField'

const Blis = () => {
  const { id } = useParams()
  const { sitesData } = useContext(Context)
  const blis = sitesData[id].blis
  const [showPassword, setShowPassword] = useState(false)
  const [toggleLoginDetails, setToggleLoginDetails] = useState(1)

  const toggleLogin = (index) => {
    setToggleLoginDetails(index)
  }

  return (
    <div className="blis">
      {blis ? (
        <Container>
          <div className="installed-grid">
            <div className="label">Installed On:</div>
            <div className="label">Live On:</div>
            <DataField>{blis.installedOn.split('T')[0]}</DataField>
            <DataField>{blis.liveOn.split('T')[0]}</DataField>
          </div>

          <div className="auth-container">
            <div className="auth-bar">
              <div className={toggleLoginDetails === 1 ? 'tabs-active' : 'tabs'} onClick={() => toggleLogin(1)}>
                Caller
              </div>
              <div className={toggleLoginDetails === 2 ? 'tabs-active' : 'tabs'} onClick={() => toggleLogin(2)}>
                POS
              </div>
              <div className={toggleLoginDetails === 3 ? 'tabs-active' : 'tabs'} onClick={() => toggleLogin(3)}>
                ADSL Router
              </div>
            </div>

            <div className="auth-content">
              <div className="grid">
                <div className="label">Login:</div>
                <div className="label">Password:</div>
              </div>
              <div className={toggleLoginDetails === 1 ? 'tabs-active grid' : 'tabs'}>
                <DataField>{blis.callersLogin}</DataField>
                <div className="DataField password">
                  {blis.callersPassword ? showPassword ? blis.callersPassword : '*******' : <div></div>}
                  <button onClick={() => setShowPassword((showPassword) => !showPassword)}>
                    <img src={require('../assets/eye.png')} alt="" />
                  </button>
                </div>
              </div>
              <div className={toggleLoginDetails === 2 ? 'tabs-active grid' : 'tabs'}>
                <DataField>{blis.posLogin}</DataField>
                <div className="DataField password">
                  {blis.posPassword ? showPassword ? blis.posPassword : '*******' : <div></div>}
                  <button onClick={() => setShowPassword((showPassword) => !showPassword)}>
                    <img src={require('../assets/eye.png')} alt="" />
                  </button>
                </div>
              </div>
              <div className={toggleLoginDetails === 3 ? 'tabs-active grid' : 'tabs'}>
                <DataField>{blis.adslLogin}</DataField>
                <div className="DataField password">
                  {blis.adsPassword ? showPassword ? blis.adsPassword : '*******' : <div></div>}
                  <button onClick={() => setShowPassword((showPassword) => !showPassword)}>
                    <img src={require('../assets/eye.png')} alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="version-label">Version:</div>
            <div className="version-grid">
              <>
                <div className="label">Caller:</div>
                <div className="label">POS:</div>
                <div className="label">BLIS units:</div>
                <DataField>{blis.callersVersion}</DataField>
                <DataField>{blis.posVersion}</DataField>
                <DataField>{blis.unitsVersion}</DataField>
              </>
            </div>
          </div>

          <div className="callersIP-row">
            <div className="callersIP-column">
              <div className="label">Callers IP:</div>
              <DataField>{blis.callersIP}</DataField>
            </div>
            <div className="mobPOS-row">
              <input type="checkbox" defaultChecked={blis.mobPos && 'checked'} disabled />
              <div>mobPOS Installed</div>
            </div>
          </div>

          <div className="cards-grid">
            <div className="label">Link Card Offset:</div>
            <div className="label">Card Count:</div>
            <DataField>{blis.linkCardOffset}</DataField>
            <DataField>{blis.linkCardCount}</DataField>
            <div className="label">Cards Per Position:</div>
            <div className="label">SiteID:</div>
            <DataField>{blis.cardsPerPos}</DataField>
            <DataField>{blis.linkSiteID}</DataField>
          </div>

          <div className="box-row">
            <div className="box-column">
              <div className="label">Cash Box Locks:</div>
              <DataField>{blis.cashBoxLocks}</DataField>
              <div className="label">Engineer Key:</div>
              <DataField>{blis.engKey}</DataField>
            </div>
            <div className="box-column">
              <div className="label">LED Batch:</div>
              <DataField>{blis.ledBatch}</DataField>
            </div>
          </div>

          <div className="notes-grid">
            <div className="label">Notes:</div>
            <Container>{blis.notes}</Container>
          </div>

          <div className="plate-grid">
            <div className="label">NGMWLiteID:</div>
            <div className="label">Plate:</div>
            <DataField>{blis.ngmwLiteID}</DataField>
            <DataField>{blis.ngmwLitePlate}</DataField>
          </div>
        </Container>
      ) : null}
    </div>
  )
}

export default Blis
