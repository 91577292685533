import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import '../css/pages/CallLog.css'
import CallLogEntry from './CallLogEntry'
import Container from './Container'

const CallLogComponent = ({ data }) => {
  const callLog = data
  const [filter, setFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('Open')
  const [tabIdFilter, setTabIdFilter] = useState(0)

  let location = useLocation().pathname

  return (
    <div className="calllog">
      {callLog ? (
        <>
          <div className="filter-row">
            <select defaultValue={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
              <option value="">All</option>
              <option value="Open">Open</option>
              <option value="User Training">User Training</option>
              <option value="Other Hardware">Other Hardware</option>
              <option value="Other Software">Other Software</option>
              <option value="Customer/3rd Party">Customer/3rd Party</option>
              <option value="Config/Setup">Config/Setup</option>
              <option value="Kiosk SW">Kiosk SW</option>
              <option value="Caller SW">Caller SW</option>
              <option value="PD SW">PD SW</option>
              <option value="Audio/site SW">Audio/site SW</option>
              <option value="Coin/Notes Acceptors">Coin/Notes Acceptors</option>
              <option value="tablets">tablets</option>
              <option value="Racks">Racks</option>
            </select>
            <select defaultValue={tabIdFilter} onChange={(e) => setTabIdFilter(e.target.value)}>
              <option value="0">Call Log</option>
              <option value="1">Swap Outs</option>
              <option value="2">Technical</option>
              <option value="3">Manufacturing</option>
              <option value="4">Change Control</option>
            </select>
            {location === '/calllog' && (
              <input
                className="filter-field"
                type="text"
                placeholder="Search site"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            )}
          </div>
          <div className="calllog-table">
            <div className="calllog-row">
              <div className="calllog-column-header"> Status</div>
              <div className="calllog-column-header"> Date</div>
              <div className="calllog-column-header"> Group</div>
              <div className="calllog-column-header"> Site</div>
            </div>
            <Container>
              {callLog
                .filter((entry) => entry.status.includes(statusFilter))
                .filter((entry) => (entry.tabID == -1 && tabIdFilter == 0) || entry.tabID == tabIdFilter)
                .filter(
                  (entry) =>
                    entry.siteName &&
                    entry.groupName &&
                    (entry.siteName.toLowerCase() + ' ' + entry.groupName.toLowerCase()).includes(filter.toLowerCase())
                )
                .sort((a, b) => (a.callDate.toLowerCase() > b.callDate.toLowerCase() ? -1 : 1))
                .map((entry, i) => (
                  <CallLogEntry
                    key={i}
                    id={entry.id}
                    date={entry.callDate.split('T')[0]}
                    status={entry.status}
                    product={entry.productCategory}
                    group={entry.groupName}
                    site={entry.siteName}
                    engineer={entry.engineer}
                    problem={entry.problem}
                    action={entry.action}
                  />
                ))}
            </Container>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default CallLogComponent
