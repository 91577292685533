import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CalendarComponent from '../components/CalendarComponent'
import Context from '../Context'
import '../css/pages/Calendar.css'

const Calendar = () => {
  const { status } = useContext(Context)

  let navigate = useNavigate()

  useEffect(() => {
    if (status && status === 401) {
      navigate('/')
    }
  }, [status])

  return (
    <div className="calendar-page">
      {' '}
      <CalendarComponent />
    </div>
  )
}

export default Calendar
