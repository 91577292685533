import React, { useContext } from 'react'
import Context from '../Context'
import { useParams } from 'react-router-dom'
import '../css/components/Address.css'
import '../css/Global.css'
import Container from './Container'
import DataField from './DataField'

const Address = () => {
  const { id } = useParams()

  const { sitesData } = useContext(Context)
  const site = sitesData[id]

  return (
    <div className="address">
      <Container>
        <div className="label">Group:</div>
        <DataField>{site.groupName}</DataField>

        <div className="label">Club:</div>
        <DataField>{site.siteName}</DataField>

        <div className="label">Address:</div>
        <DataField>{site.address}</DataField>

        <div className="label">PostCode:</div>
        <DataField>{site.postcode}</DataField>

        <div className="label">Phone1:</div>
        <DataField>{site.phone1}</DataField>

        <div className="label">Phone2:</div>
        <DataField>{site.phone2}</DataField>

        <div className="label">Fax:</div>
        <DataField>{site.fax}</DataField>

        <div className="label">Email:</div>
        <DataField>{site.email}</DataField>
      </Container>
    </div>
  )
}

export default Address
