import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import '../css/components/CalendarComponent.css'
import '../css/pages/Calendar.css'
import Loading from './Loading'
import axios from 'axios'

const CalendarComponent = () => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const weekDays = ['Su', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sa']

  const [month, setMonth] = useState(new Date().getMonth())
  const [year, setYear] = useState(new Date().getFullYear())
  let days = ''
  let firstDayOfMonth = new Date(year, month).getDay()
  const [daysInMonth, setDaysInMonth] = useState([])

  //const [onCallData, setOnCallData] = useState([])
  const [cookies] = useCookies()
  const [monthlyHolidays, setMonthlyHolidays] = useState([])
  const [monthlyBankHolidays, setMonthlyBankHolidays] = useState([])
  const [toggleState, setToggleState] = useState(1)

  //could do to grab the users from a request from the server
  const [staff, setStaff] = useState([])

  // const staff = [
  //   ' ',
  //   'Oliver Copley',
  //   'Andy Shell',
  //   'Luke Dixon',
  //   'Sam Smith',
  //   'Rob Wilson',
  //   'Stuart Woodmansey',
  //   'Darran Thornton',
  //   'Debra Jowett',
  //   'Jason Turner',
  //   'Gary Wilson',
  //   'Ileana Rotaru',
  //   'Paul Dunnett',
  //   'Ehren Patel',
  //   'Chris Shipley',
  //   'Justyna Orzechowska',
  //   'Daniel Siklodi',
  //   'Mihaela Iancu',
  //   'Scott Daysley'
  // ]

  const [loading, setLoading] = useState(false)

  //functions for setting up the calendar
  const getDays = () => {
    days = new Date(year, month + 1, 0).getDate()
    return days
  }

  const getFirstDayOfMonth = () => {
    firstDayOfMonth = new Date(year, month, 1).getDay()
    return firstDayOfMonth
  }

  const getDaysInMonth = () => {
    setDaysInMonth((daysInMonth) => [])
    for (let i = 1; i <= days; i++) {
      setDaysInMonth((daysInMonth) => [...daysInMonth, i])
    }
  }

  const increaseMonth = () => {
    month === 11 && setYear((year) => year + 1)
    setMonth((month) => Math.abs((month + 1) % 12))
  }

  const decreaseMonth = () => {
    month === 0 && setYear((year) => year - 1)
    setMonth((month) => Math.abs((month + 11) % 12))
  }

  const getCalendar = async (cookies) => {
    setLoading(false)
    await axios
      .get(`https://bingoplus24.com:55770/${toggleState === 1 ? 'getCalendar' : 'onCall'}`, {
        params: {
          start: `${new Date(year, month).toLocaleDateString().split('/').reverse().join('-')}T00:00:00`,
          end: `${
            month === 11
              ? new Date(year + 1, month + 1).toLocaleDateString().split('/').reverse().join('-')
              : new Date(year, month + 1).toLocaleDateString().split('/').reverse().join('-')
          }T00:00:00`
        },
        headers: {
          Authorization: `token ${cookies.jwt}`
        }
      })
      .then((response) => {
        setMonthlyHolidays([])
        let tempDays = []
        if (toggleState === 2) {
          let tempOnCall = []

          try {
            response.data.forEach((entry) =>
              entry.months.forEach((month) =>
                month.days.forEach((day) => {
                  let calDate = new Date(entry.yr, month.mn - 1, day.callDay)
                    .toLocaleDateString('pt-br')
                    .split('/')
                    .reverse()
                    .join('-')
                  let dayOff = { calDate: calDate, fullName: day.fullName }
                  tempOnCall.push(dayOff)
                })
              )
            )

            tempOnCall.map((entry) =>
              entry.mode
                ? entry.mode !== 'Install' &&
                  entry.mode !== 'Service' &&
                  entry.mode !== 'Training' &&
                  tempDays.push(entry)
                : tempDays.push(entry)
            )
            setMonthlyHolidays(tempDays)
          } catch (error) {
            console.log(error)
          }
        } else {
          try {
            response.data.map((entry) =>
              entry.mode
                ? entry.mode !== 'Install' &&
                  entry.mode !== 'Service' &&
                  entry.mode !== 'Training' &&
                  tempDays.push(entry)
                : tempDays.push(entry)
            )
            setMonthlyHolidays(tempDays)
          } catch (error) {
            console.log(error)
          }
        }

        setStaff([' ', ...new Set(tempDays.map((item) => item.fullName))])
        setLoading(true)
      })
      .catch(function (error) {
        console.log('error', error)
      })
  }

  const getBankHolidays = async (cookies, state) => {
    await axios
      .get('https://bingoplus24.com:55770/getBankHolidays', {
        headers: {
          Authorization: `token ${cookies.jwt}`
        }
      })
      .then((response) => {
        state(response.data)
      })
      .catch(function (error) {
        console.log('error', error)
      })
  }

  useEffect(() => {
    getBankHolidays(cookies, setMonthlyBankHolidays)
    getCalendar(cookies)
    getDays()
    getFirstDayOfMonth()
    getDaysInMonth()
  }, [month, toggleState])

  return (
    <>
      <div className="calendarComp">
        <div className="calendar">
          <div>
            <div className="header">
              <button className="change-month-btn" onClick={() => decreaseMonth()}>
                {'<'}
              </button>
              <div
                className={`tab ${toggleState === 1 && 'tab-active'}`}
                onClick={() => {
                  setToggleState(1)
                }}
              >
                Holidays
              </div>
              <h3 style={{ color: 'white', width: '130px', textAlign: 'center' }}>
                {year} <br />
                {months[month]}
              </h3>
              <div
                className={`tab ${toggleState === 2 && 'tab-active'}`}
                onClick={() => {
                  setToggleState(2)
                }}
              >
                On call
              </div>
              <button className="change-month-btn" onClick={() => increaseMonth()}>
                {'>'}
              </button>
            </div>
            {daysInMonth !== [] && monthlyHolidays !== [] && monthlyBankHolidays !== [] && loading ? (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ backgroundColor: 'white' }}>
                  {staff.map((emp, i) => (
                    <div
                      key={i}
                      style={{
                        width: '100px',
                        height: '40px',
                        padding: '5px',
                        backgroundColor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        boxShadow: '0 0 2px rgba(197, 193, 188, 0.5)'
                      }}
                    >
                      {emp}
                    </div>
                  ))}
                </div>
                <div className="days">
                  {staff.map((emp, i) => (
                    <div style={{ display: 'flex', flexDirection: 'row' }} key={i}>
                      {daysInMonth.map((day, i) => (
                        <div style={{ fontSize: '13px' }} className="month-days" key={i}>
                          <div className="daysoff-container">
                            <div style={{ textAlign: 'center', minWidth: '60px' }}>
                              <div>{emp === ' ' && day}</div>
                              <div>{emp === ' ' && weekDays[new Date(year, month, day).getDay()]}</div>
                            </div>

                            {monthlyHolidays.map(
                              (dayOff, i) =>
                                new Date(year, month, day)
                                  .toLocaleDateString('pt-br')
                                  .split('/')
                                  .reverse()
                                  .join('-') === dayOff.calDate.split('T')[0] &&
                                emp.split(' ')[0] === dayOff.fullName.split(' ')[0] &&
                                (dayOff.mode &&
                                monthlyBankHolidays.some(
                                  (e) =>
                                    e.holiday.split('T')[0] ===
                                    new Date(year, month, day)
                                      .toLocaleDateString('pt-br')
                                      .split('/')
                                      .reverse()
                                      .join('-')
                                ) ? (
                                  <div key={i} className="month-day bank-holiday">
                                    Bnk Hol
                                  </div>
                                ) : (
                                  <div
                                    key={i}
                                    className={`month-day ${
                                      dayOff.mode
                                        ? dayOff.mode === 'Holiday'
                                          ? 'holiday'
                                          : dayOff.mode === 'Sick'
                                          ? 'sickday'
                                          : 'lieu'
                                        : 'onCall'
                                    } ${
                                      dayOff.dayLength
                                        ? dayOff.dayLength === 'Full'
                                          ? ''
                                          : dayOff.dayLength === 'AM'
                                          ? 'AM'
                                          : 'PM'
                                        : ''
                                    }`}
                                  >
                                    {dayOff.mode ? dayOff.mode : 'On Call'}
                                  </div>
                                ))
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default CalendarComponent
